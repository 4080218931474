"use client";

import * as React from "react";
import Link from "next/link";
import { ArrowLeft } from "lucide-react";

import { ClientOnly } from "@/components/client-only";
import { FreeTrialWrapper } from "@/components/free-trial-wrapper";
import SaleWrapper from "@/components/sale-wrapper";
import { ExaminePlusLink } from "../cta";
import { AccessCheck } from "../access-check";
import { Actions } from "@/components/action-buttons";
import { Page } from "@/types";

interface ContentRightProps extends React.HTMLAttributes<HTMLDivElement> {
	page?: Page
}

const randomMessage = [
	<p key="0" className="text-sm">
		<em>Examine provides institutional access.</em>{" "}
		<Link href="/contact" className="text-primary !underline">
			Contact us
		</Link>{" "}
		for more details.
	</p>,
	<p key="1" className="text-sm">
		Are you a librarian?{" "}
		<Link href="/insiders/librarians" className="text-primary !underline">
			Learn more about how we work with libraries
		</Link>
		.
	</p>,
][Math.floor(Math.random() * 2)];

const ContentRight = ({ children, page }: ContentRightProps) => {
	return (
		<>
			<input
				type="checkbox"
				id="right-toggle"
				className="peer/right hidden"
				defaultChecked={true}
				onChange={(e) => {
					window.mixpanel.track("Toggle right sidebar", {
						state: e.target.checked,
					});
				}}
			/>
			<aside className="sticky top-3 hidden h-screen w-full max-w-15 space-y-5 peer-checked/right:overflow-y-scroll px-3 py-9.5 shadow-right-side-inner transition-[max-width] duration-200 ease-in-out peer-checked/right:max-w-xs lg:block">
				<label
					htmlFor="right-toggle"
					className="mb-2 ml-auto mr-2 block size-4 cursor-pointer rounded border-[1.5px] border-current text-gray-600"
				>
					<ArrowLeft
						size={13}
						className="transition-transform duration-200 ease-in [input:checked+aside_&]:rotate-180"
					/>
				</label>
				<div className="invisible flex flex-col gap-y-4 [input:checked+aside_&]:visible">
					{children}
					{page && <Actions page={page} />}
					<ClientOnly>
						<AccessCheck feature={["study_summaries", "examine_database", "supplement_guides"]}>
							<div className="pt-8">
								<SaleWrapper
									cta={
										<div className="rounded-lg bg-examine-purple-100 p-4 text-gray-900">
											<div className="space-y-5">
												<b>Stay up to date</b>
												<p>
													Access the latest research, reviewed and explained in-depth by a
													multidisciplinary team of experts. Examine is a{" "}
													<Link href="/about" className="text-primary !underline">
														completely independent organization
													</Link>
													. No industry ties, no sponsors, no bias.
												</p>
												<ExaminePlusLink
													trackDetails={{
														location: "r menu",
														label: "Try free for 7 days",
													}}
													className="inline-block rounded-full bg-premium px-3 py-1.5 text-center font-bold text-primary transition-colors hover:bg-primary hover:text-white xl:px-5 xl:py-2"
												>
													<FreeTrialWrapper cta="Get started with Examine+ today">
														Try free for 7 days
													</FreeTrialWrapper>
												</ExaminePlusLink>
												{randomMessage}
											</div>
										</div>
									}
								/>
							</div>
						</AccessCheck>
					</ClientOnly>
				</div>
			</aside>
		</>
	);
};

export default ContentRight;
