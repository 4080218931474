import * as React from "react";
import useSWR from "swr";
import { usePathname } from "next/navigation";
import {
	FacebookIcon,
	FacebookShareButton,
	LinkedinIcon,
	LinkedinShareButton,
	TwitterIcon,
	TwitterShareButton,
} from "react-share";
import { getCookie, hasCookie } from "cookies-next";

import { User } from "@/types/index";
import { cn } from "@/lib/utils";
import { SITE_URL } from "@/lib/utils/index";
import { useUser } from "@/lib/hooks/auth";
import { useSubscription } from "@/lib/hooks/use-subscription";
import { swrFetcher } from "@/lib/hooks/fetch-client";
import { useEnterprise } from "@/lib/hooks/use-enterprise";
import Link from "@/components/ui/link";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { toast } from "@/components/ui/use-toast";
import { Button } from "@/components/ui/button";
import { LoginLink } from "@/components/login-link";

interface ShareButtonsProps {
	shareLink: string;
	shareText?: string;
	desc?: string;
}

interface ReferEarnProps extends React.HTMLAttributes<HTMLDivElement> {
	url: string;
	children: React.ReactNode;
	triggerClassNames?: string;
	desc?: string;
}

const ShareButtons = ({
	shareLink,
	shareText = "Or share on social media now:",
}: ShareButtonsProps) => {
	const trackShare = (network: string) => {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer?.push({
			event: "share",
			method: network,
			shareLink,
		});
	};

	const getLink = () => {
		if (shareLink?.startsWith("http")) {
			return shareLink;
		}
		return `${SITE_URL}${shareLink}`;
	};

	return (
		<>
			<div className="mb-2 mt-3 font-semibold">{shareText}</div>
			<div className="my-2 flex items-center space-x-3">
				<div className="rounded bg-white px-2 py-1.5 transition-transform duration-200 hover:-translate-y-1">
					<FacebookShareButton
						className="block"
						url={getLink()}
						onClick={() => trackShare("Facebook")}
					>
						<FacebookIcon round={true} size={30}></FacebookIcon>
					</FacebookShareButton>
				</div>
				<div className="rounded bg-white px-2 py-1.5 transition-transform duration-200 hover:-translate-y-1">
					<TwitterShareButton
						className="block"
						url={getLink()}
						via="Examinecom"
						onClick={() => trackShare("Twitter")}
					>
						<TwitterIcon round={true} size={30}></TwitterIcon>
					</TwitterShareButton>
				</div>
				<div className="rounded bg-white px-2 py-1.5 transition-transform duration-200 hover:-translate-y-1">
					<LinkedinShareButton
						className="block"
						url={getLink()}
						source={SITE_URL}
						onClick={() => trackShare("Linkedin")}
					>
						<LinkedinIcon round={true} size={30}></LinkedinIcon>
					</LinkedinShareButton>
				</div>
			</div>
		</>
	);
};

export function ReferEarnOld({ closeModal, desc }: any) {
	const { user } = useUser();
	const { isEnterpriseUser } = useSubscription();
	const pathname = usePathname();

	let shareLink = pathname;
	let addSocial = true;
	if (user && user.affiliate_info?.code && !isEnterpriseUser) {
		shareLink = `${process.env.NEXT_PUBLIC_SITE_URL}/refer/${user.affiliate_info?.code}`;
		shareLink += `?loc=${pathname}`;
		addSocial = false;
	}

	if (isEnterpriseUser) {
		return (
			<div className="relative mb-10 max-w-sm rounded-md bg-white p-6 pt-10 shadow-xl sm:p-8 sm:pt-12 md:max-w-md xl:max-w-xl">
				<a
					className="absolute right-5 top-3 cursor-pointer sm:right-6 sm:top-4"
					onClick={closeModal}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						strokeWidth={2}
						stroke="currentColor"
						className="size-6 sm:size-7"
					>
						<path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
					</svg>
				</a>

				<ShareButtons shareLink={shareLink} shareText="Share on social media:" desc={desc} />
			</div>
		);
	}
}

export default function ReferEarn({ url, desc, children }: ReferEarnProps) {
	const token = hasCookie("token") ? getCookie("token") : null;
	const { data: user } = useSWR<User>(token ? ["/v1/user", token] : null, swrFetcher);
	const { data: profile } = useSWR(token ? ["/v1/affiliate/profile", token] : null, swrFetcher);
	const referralRef = React.useRef(null);
	const [addSocial, setAddSocial] = React.useState(true);
	const [shareLink, setShareLink] = React.useState("");
	const { isEnterpriseUser } = useEnterprise();
	const referralLink = `${process.env.NEXT_PUBLIC_SITE_URL}/refer/${profile?.code}`;

	React.useEffect(() => {
		const getURI = () => {
			if (url.startsWith("http")) {
				return "/" + url.split("/").splice(3).join("/");
			}

			return url;
		};

		if (user && user?.affiliate_info?.code && !isEnterpriseUser) {
			let link = `${process.env.NEXT_PUBLIC_SITE_URL}/refer/${user.affiliate_info?.code}`;
			if (url) {
				link = `${link}?loc=${getURI()}`;
			}
			setShareLink(link);
			setAddSocial(false);
		} else {
			setShareLink(url);
			setAddSocial(true);
		}
	}, [user, isEnterpriseUser, url]);

	const copy = async (e: React.MouseEvent<HTMLElement>) => {
		if (navigator.clipboard) {
			await navigator.clipboard.writeText(referralLink);
		} else {
			referralRef.current.select();
			document.execCommand("copy");
			(e.target as HTMLButtonElement).focus();
		}

		toast({ title: "Copied!" });
	};

	return (
		<Dialog>
			<DialogTrigger asChild>{children}</DialogTrigger>
			<DialogContent
				className={cn(isEnterpriseUser ? "max-w-[15rem] justify-center" : "sm:max-w-xl")}
			>
				{isEnterpriseUser ? (
					<ShareButtons shareLink={shareLink} shareText="Share on social media:" desc={desc} />
				) : (
					<section>
						<h1 className="mb-2 text-base font-semibold sm:text-18">Link to this page and earn</h1>

						{user ? (
							<>
								<p className="mb-5">
									Share this page with a friend and earn a 33% commission if they sign up for
									Examine+ through your unique referral link!{" "}
									<Link href="/members/myreferrals/" className="font-bold underline">
										Click here
									</Link>{" "}
									to{" "}
									{user?.affiliate_info?.code
										? "see your referral stats and to learn more"
										: "set your referral code and to learn more"}
									.
								</p>
								{profile?.code ? (
									<div className="flex overflow-hidden rounded border border-examine-green-500">
										<Input
											readOnly={true}
											ref={referralRef}
											type="text"
											value={referralLink}
											className="flex-auto border-0 outline-0"
										/>
										<Button
											className="rounded-l-none bg-examine-green-500 px-5 py-2 text-white"
											onClick={(e) => copy(e)}
										>
											Copy
										</Button>
									</div>
								) : null}
							</>
						) : (
							<p className="mb-5">
								Earn a 33% commission if you share this page with a friend and they sign up for
								Examine+!{" "}
								<Link href="/signup/" className="font-semibold underline">
									Sign up
								</Link>{" "}
								or{" "}
								<LoginLink className="font-semibold underline text-examine-purple-400">
									log in
								</LoginLink>{" "}
								to start referring.
								<br />
								<br />
								<Link href="/referral-program/" className="font-semibold underline">
									Learn more
								</Link>{" "}
								about our referral program.
							</p>
						)}

						{addSocial ? <ShareButtons shareLink={shareLink} desc={desc} /> : null}
					</section>
				)}
			</DialogContent>
		</Dialog>
	);
}
