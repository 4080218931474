"use client";

import * as React from "react";

import { ArrowRight } from "lucide-react";

interface ContentLeftProps extends React.HTMLAttributes<HTMLDivElement> {}

const ContentLeft = ({ children }: ContentLeftProps) => {
	const [collapsed, setCollapsed] = React.useState(true);

	React.useEffect(() => {
		const handleResize = () => {
			const isMobile = window.innerWidth <= 1440;

			setCollapsed(!isMobile);
		};

		handleResize();

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<>
			<input
				type="checkbox"
				id="left-toggle"
				className="peer/left hidden"
				checked={collapsed}
				onChange={(e) => {
					window.mixpanel.track("Toggle left sidebar", {
						state: e.target.checked,
					});
					setCollapsed((prev) => !prev);
				}}
			/>
			<nav className="group sticky top-20 hidden size-full max-w-15 space-y-5 whitespace-nowrap px-3 py-9.5 text-gray-700 shadow-left-side-inner transition-[max-width] duration-200 ease-in-out peer-checked/left:max-w-[16rem] peer-checked/left:whitespace-normal lg:block 3xl:peer-checked/left:max-w-xs">
				<label
					htmlFor="left-toggle"
					className="ml-2 inline-block size-4 cursor-pointer rounded border-[1.5px] border-current text-gray-600"
				>
					<ArrowRight
						size={13}
						className="transition-transform duration-200 ease-in [input:checked+nav_&]:rotate-180"
					/>
				</label>
				<div className="space-y-3 overflow-hidden">{children}</div>
			</nav>
		</>
	);
};

export default ContentLeft;
