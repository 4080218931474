import * as React from "react";

import { cn } from "@/lib/utils";

export interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
	({ className, rows, ...props }, ref) => {
		return (
			<textarea
				className={cn(
					"border-input ring-offset-background placeholder:text-muted-foreground flex min-h-[90px] w-full rounded-md border bg-transparent px-3 py-2 file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-examine-purple-400 focus-visible:ring-offset-0 disabled:cursor-not-allowed disabled:opacity-50",
					className
				)}
				rows={rows}
				ref={ref}
				{...props}
			/>
		);
	}
);
Textarea.displayName = "Textarea";

export { Textarea };
