"use client";

import dynamic from "next/dynamic";
import { ShareIcon } from "lucide-react";
import { usePathname } from "next/navigation";

import { AddNote } from "@/components/add-note";
import { Button } from "@/components/ui/button";
import { Page } from "types";
import ReferEarn from "@/components/refer-earn";
import { SaveButton } from "@/components/saved-pages";
import { useSubscription } from "@/lib/hooks/use-subscription";

const FeedbackFishWidget = dynamic(() => import("@/components/feedback-fish"));
const contentPages = [
	"/supplements/",
	"/categories/",
	"/conditions/",
	"/outcomes/",
	"/faq/",
	"/guides/",
	"/research-feed/study/",
	"/diets/",
	"/foods/",
	"/other/",
	"/articles/",
	"/glossary/",
];
const pagesWithNestedFaqs = [
	"/supplements/",
	"/categories/",
	"/conditions/",
	"/outcomes/",
	"/diets/",
	"/foods/",
	"/other/",
];

interface ActionsProps {
	page: Page;
}

const Actions = ({ page }: ActionsProps) => {
	const pathname = usePathname();
	const { isEnterpriseUser } = useSubscription();

	const isContentPage = contentPages.some((page) => pathname.startsWith(page));
	const isNestedFaqPage = pagesWithNestedFaqs.some(
		(page) => pathname.startsWith(page) && pathname.includes("/faq/")
	);

	const showActions = isContentPage && !isNestedFaqPage;

	if (!showActions) return null;

	return (
		<div className="flex w-full justify-around gap-1 p-4 md:flex-col md:space-y-4">
			{/* Save */}
			<SaveButton
				variant="outline"
				page={page}
				className="justify-center space-x-2 rounded-md font-medium md:w-full"
			/>

			{/* Add note */}
			<AddNote variant="outline" page={page} />

			{/* Send feedback */}
			<FeedbackFishWidget
				className="mt-0 w-auto space-x-2 rounded-md font-medium md:w-full"
				variant="outline"
			>
				<span>Send feedback</span>
			</FeedbackFishWidget>

			{/* Refer and earn */}
			<ReferEarn desc={"overview TODO"} url={page?.url}>
				<Button
					size="lg"
					variant="outline"
					className="justify-center space-x-2 text-center font-medium md:w-full"
				>
					<ShareIcon className="size-4 lg:size-5" />
					<span className="hidden text-xs md:inline lg:text-sm xl:text-base">
						{isEnterpriseUser ? "Share" : "Refer and Earn"}
					</span>
				</Button>
			</ReferEarn>
		</div>
	);
};

const MobileActionsToolbar = (props: ActionsProps) => {
	return (
		<div className="fixed bottom-0 left-0 right-0 z-50 flex justify-around bg-white p-4 md:hidden">
			<Actions {...props} />
		</div>
	);
};

export { Actions, MobileActionsToolbar };
