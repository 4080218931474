"use client";

import { usePathname, useRouter } from "next/navigation";
import { useEffect } from "react";

import { fetchWrapper } from "@/lib/hooks/fetch-client";

interface RedirectData {
	data: {
		path: string;
	};
}

const Redirect = () => {
	const router = useRouter();
	const pathname = usePathname();

	useEffect(() => {
		const redirect = async () => {
			const data = await fetchWrapper<RedirectData>("/v1/redirect", {
				method: "POST",
				body: JSON.stringify({ path: pathname }),
			});
			const destination = data?.data?.path || null;

			if (destination && destination !== pathname) {
				router.push(destination);
			}
		};

		redirect();
	}, [pathname, router]);

	return null;
};

export default Redirect;
